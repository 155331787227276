
:root {
    //color
    --black: #4C4D4F;
    --white: #FAFBFC;
    --green: #16A055;

    //font style
    --font-primary: 'Poppins', sans-serif;

    //font color
    --color-primary: #4C4D4F;


}