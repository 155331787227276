@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&family=Poppins:wght@400;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
@import "vars";
body {
    font-family: var(--font-primary);/*update this to site font*/
    font-size: 16px;
    color: var(--color-primary); /* update this to site body colour */
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: var(--black);
    background-color: var(--white);
    border: 2px solid var(--green);
    box-sizing: border-box;
    transition: all 0.3s ease;
    font-weight: 400;
    width: 300px;
}
.button:hover {
    color: var(--white);
    background-color: var(--green);
    font-weight: 600;
}

.button-secondary {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: var(--white);
    background-color: var(--green);
    border: 2px solid var(--white);
    box-sizing: border-box;
    transition: transform 0.3s ease;
}
.button-secondary:hover {
    transform: scale(1.1);
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.full-width{
    width:100%;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}
.text-green{
    color: var(--green);
}
.text-black{
    color: var(--black);
}
.text-white{
    color: var(--white);
}
.text-title{
    font-size:clamp(18px, 4vw, 30px);
    line-height: clamp(20px, 4.5vw, 46px);;
    font-weight: 600;
}
.text-semibold{
    font-weight: 600;
}